import { Link } from 'gatsby';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { parseDate } from '../utils/parseDate';
import { colors } from '../utils/siteVars';
import BtnNoStyle from './BtnNoStyle';
import Button from './Button';
import Card from './Card';
import Checkbox from './Checkbox';

const StyledNotificationCard = styled.div`
  .notification-card {
    display: grid;
    ${({ hasBatchSelection }) =>
      hasBatchSelection
        ? 'grid-template-columns: 20px auto;'
        : 'grid-template-columns: auto;'}
    gap: 1rem;
    align-items: center;
    background: ${(props) => props.alertColor};
    ${({ isInternal }) =>
      isInternal &&
      'background: ' + colors.lightBlue + '; color: ' + colors.white + ';'}
  }
  .notification-top {
    margin-bottom: 0.3rem;
    p {
      opacity: 0.5;
      font-size: 0.9rem;
    }
  }

  display: grid;
  grid-template-columns: 1fr 30px;
  align-items: center;
  gap: 0.5rem;
  img {
    width: 100%;
    margin: 0;
  }
  .notification-button-container {
    position: relative;
    margin-top: 1rem;
    .notification-menu-container {
      position: absolute;
      top: 1.2rem;
      right: 0;
      padding: 0.5rem;
      background: ${colors.white};
      border-radius: 10px;
      box-shadow: 6px 6px 12px rgba(0, 0, 0, 0.1);
      pointer-events: none;
      opacity: 0;
      z-index: 2;
      transition: 100ms ease-in-out;
      button {
        width: 100%;
        margin-bottom: 0.5rem;
        white-space: nowrap;
      }
    }
    &:hover {
      .notification-menu-container {
        opacity: 1;
        pointer-events: initial;
      }
    }
  }
`;

const NotificationCard = (props) => {
  const { notification, batchSelection, toggleBatchSelection } = props;
  const [alertLevel, setAlertLevel] = useState(0);

  const alertLevelColors = [
    colors.lightGrey,
    colors.yellowAlert,
    colors.orangeAlert,
    colors.redAlert,
  ];

  useEffect(() => {
    const TIME_TO_ALERT_3 = 0;
    const TIME_TO_ALERT_2 = 1000 * 60 * 60 * 24 * 7;
    const TIME_TO_ALERT_1 = 1000 * 60 * 60 * 24 * 30;
    const today = new Date().getTime();
    const notificationDate = new Date(notification.dateForHandling).getTime();
    const timeDiff = notificationDate - today;

    if (timeDiff < TIME_TO_ALERT_1) {
      setAlertLevel(1);
      if (timeDiff < TIME_TO_ALERT_2) {
        setAlertLevel(2);
        if (timeDiff < TIME_TO_ALERT_3) {
          setAlertLevel(3);
        }
      }
    } else {
      setAlertLevel(0);
    }
  }, [notification]);
  return (
    <StyledNotificationCard
      hasBatchSelection={!!batchSelection}
      noBtn={props.noBtn}
      isInternal={notification.isInternal}
      alertColor={
        notification.archived
          ? alertLevelColors[0]
          : alertLevelColors[alertLevel]
      }
    >
      <Card inner className="notification-card">
        {batchSelection && (
          <div>
            <Checkbox
              id={`notificationCheck-${notification._id}`}
              checked={!!batchSelection.find((n) => n._id === notification._id)}
              onChange={() => toggleBatchSelection(notification)}
            />
          </div>
        )}
        <div>
          <div className="notification-top">
            <p>{`${parseDate(notification.dateForHandling)} - ${
              notification.recipientFirstName
            } ${notification.recipientLastName}`}</p>
            <p>
              {notification.customerName || ''}
              {notification.reservoirName
                ? ' - ' + notification.reservoirName
                : ''}
            </p>
          </div>
          <p>
            {notification.isInternal
              ? notification.subject
              : notification.textContent}
          </p>
        </div>
      </Card>
      <div className="notification-button-container">
        <BtnNoStyle>
          <img src="/icons/3-dots.png" alt="" />
        </BtnNoStyle>
        <div className="notification-menu-container">
          {notification.link && !props.noBtn ? (
            <Link
              to={notification.link}
              state={{
                linkKey: notification.linkKey || null,
                notification: notification.linkKey ? notification : null,
              }}
            >
              <Button small>Gå till notis</Button>
            </Link>
          ) : null}
          {props.hasEdit ? (
            <Button small onClick={props.editNotification}>
              Redigera
            </Button>
          ) : null}
          {props.noBtn ? (
            <Button small onClick={() => props.showNotification(notification)}>
              Visa notis
            </Button>
          ) : null}
          {notification.isInternal ? (
            <Button
              small
              onClick={() => props.showInternalNotification(notification)}
            >
              Visa notis
            </Button>
          ) : null}
          {props.my ? (
            <>
              {!props.archived ? (
                <Button
                  small
                  onClick={() => {
                    props.archiveNotification(notification);
                  }}
                >
                  Arkivera
                </Button>
              ) : null}
              <Button
                small
                look="danger"
                onClick={() => props.preDeleteNotification(notification)}
              >
                Radera
              </Button>
            </>
          ) : null}
        </div>
      </div>
    </StyledNotificationCard>
  );
};

export default NotificationCard;
