import React, { useContext, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import OverlayBox from './OverlayBox';
import { getPersons } from '../utils/persons.util';
import {
  GlobalDispatchContext,
  GlobalStateContext,
} from '../context/GlobalContextProvider';
import Button from './Button';

const StyledContactPersonSelectModal = styled.div`
  height: 50vh;
  .contact-person-card {
    border: 1px solid black;
    border-radius: 5px;
    padding: 0.5rem;
    margin-bottom: 0.5rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    button {
      margin: 0;
    }
  }
`;

const ContactPersonSelectModal = ({ onSelect, onClose }) => {
  const state = useContext(GlobalStateContext);
  const dispatch = useContext(GlobalDispatchContext);

  const [persons, setPersons] = useState([]);
  const [searchString, setSearchString] = useState('');

  const fetchData = async () => {
    const { persons } = await getPersons({ state, dispatch });
    if (persons) {
      setPersons(persons.filter((person) => !!person.name));
    }
  };

  const filteredPersons = useMemo(() => {
    if (!searchString) return persons;
    return persons.filter((person) => {
      return (
        person.name.toLowerCase().includes(searchString.toLowerCase()) ||
        person.email.toLowerCase().includes(searchString.toLowerCase())
      );
    });
  }, [persons, searchString]);

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <OverlayBox
      active
      close={onClose}
      header="Lägg till befintlig kontaktperson"
    >
      <StyledContactPersonSelectModal>
        <input
          type="search"
          value={searchString}
          placeholder="Sök kontaktperson"
          onChange={(e) => setSearchString(e.target.value)}
        />
        {filteredPersons.map((person) => (
          <div
            className="contact-person-card"
            key={person._id}
            value={person._id}
          >
            <span>
              {person.name}, {person.email}
            </span>
            <Button small onClick={() => onSelect(person)}>
              Lägg till
            </Button>
          </div>
        ))}
      </StyledContactPersonSelectModal>
    </OverlayBox>
  );
};

export default ContactPersonSelectModal;
