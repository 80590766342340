import React from 'react';
import styled from 'styled-components';
import OverlayBox from './OverlayBox';
import Button from './Button';

const StyledDeleteConfirmation = styled.div``;

const DeleteConfirmation = ({
  close,
  commit,
  item,
  itemIdentifier,
  itemName,
  copyOverride,
}) => {
  return (
    <StyledDeleteConfirmation>
      <OverlayBox active header={`Radera ${itemIdentifier}?`} close={close}>
        {copyOverride || (
          <>
            <p>
              Vill du <strong>permanent radera</strong> {itemIdentifier}?
            </p>
            <p>
              Det går <strong>inte</strong> att återskapa en raderad {itemName}.
            </p>
          </>
        )}
        <Button
          data-test="commit-delete-button"
          look="danger"
          small
          onClick={() => commit(item)}
        >
          Radera
        </Button>
        <Button small onClick={close}>
          Avbryt
        </Button>
      </OverlayBox>
    </StyledDeleteConfirmation>
  );
};

export default DeleteConfirmation;
