import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import Button from './Button';
import DateInput from './DateInput';

const StyledNotificationForm = styled.form``;

const notificationDefault = {
  dateForHandling: '',
  recipientId: '',
  textContent: '',
  textContentLong: [],
};

const NotificationForm = ({ handleSubmit, users, customer, data }) => {
  const [formData, setFormData] = useState(notificationDefault);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const onSubmit = (e) => {
    e.preventDefault();
    const user = users.find((user) => user._id === formData.recipientId);
    const payload = {
      ...formData,
      customerId: customer._id,
      customerName: customer.customerInformation.name,
      subject: 'customerContactNotification',
      link: `/customers/single/?id=${customer._id}`,
      linkKey: 'notifications-popup',
      recipientFirstName: user?.firstName || '',
      recipientLastName: user?.lastName || '',
    };
    handleSubmit({ notificationData: payload }, () =>
      setFormData(notificationDefault)
    );
  };

  useEffect(() => {
    if (data) setFormData(data);
  }, [data]);

  return (
    <StyledNotificationForm onSubmit={onSubmit}>
      <p>Datum för hantering</p>
      <DateInput
        required
        value={formData.dateForHandling}
        name="dateForHandling"
        onChange={handleChange}
      />
      {/* eslint-disable-next-line jsx-a11y/no-onchange */}
      <select
        required
        value={formData.recipientId}
        onChange={handleChange}
        name="recipientId"
      >
        <option disabled value="">
          Hanteras av
        </option>
        {users.map((user) => (
          <option key={user._id} value={user._id}>
            {user.firstName + ' ' + user.lastName}
          </option>
        ))}
      </select>
      <input
        required
        type="text"
        placeholder="Kontaktorsak"
        name="textContent"
        value={formData.textContent}
        onChange={handleChange}
      />
      <textarea
        required
        placeholder="Kommentar"
        name="textContentLong"
        value={formData.textContentLong.join('\n')}
        onChange={(e) => {
          setFormData({
            ...formData,
            [e.target.name]: e.target.value.split('\n'),
          });
        }}
      />
      <Button small type="submit">
        Spara notis
      </Button>
    </StyledNotificationForm>
  );
};

export default NotificationForm;
