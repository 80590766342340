import { months } from './data';

export const getProtocolFilename = (
  reservoirName,
  customerName,
  protocolName,
  dateForInspection
) => {
  const dateObj = new Date(dateForInspection);
  return `${reservoirName}_${customerName}_${protocolName}_${dateObj.getFullYear()}_${
    months[dateObj.getMonth()]
  }.pdf`;
};

export const getProtocolString = (dateForInspection, protocolName) => {
  const dateObj = new Date(dateForInspection);
  return `${dateObj.getFullYear()} ${
    months[dateObj.getMonth()]
  } ${protocolName}`;
};
