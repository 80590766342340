import axios from 'axios';
import { do_url } from './siteVars';
import { captureException } from '@sentry/gatsby';

export const request = async ({
  state,
  dispatch,
  path,
  method = 'GET',
  successText,
  errorText,
  body,
  isDoServer,
  skipLoader,
}) => {
  const action = (payload) => {
    if (dispatch) dispatch(payload);
  };
  try {
    const base = isDoServer ? do_url : process.env.GATSBY_BACKEND_API_URL;
    const pathname =
      isDoServer && state.token ? path.replace('{token}', state.token) : path;
    const url = `${base}${pathname}`;
    const headers = {
      'Content-Type': 'application/json',
    };
    if (state.token) headers.Authorization = `Bearer ${state.token}`;
    const options = {
      method,
      body,
      headers,
    };
    if (body) options.body = JSON.stringify(body);
    if (!skipLoader) action({ type: 'LOADING', value: true });
    const response = await fetch(url, options);
    const result = await response.json();
    if (!response.ok) throw result;
    if (successText) action({ type: 'ALERT', content: successText });
    return { data: result };
  } catch (err) {
    console.error(err);
    captureException(err);
    action({ type: 'ALERT', content: errorText || 'Något gick fel' });
    return { err };
  } finally {
    if (!skipLoader) action({ type: 'LOADING', value: false });
  }
};

export const progressRequest = async ({
  state,
  dispatch,
  path,
  method = 'GET',
  successText,
  errorText,
  body,
}) => {
  const action = (payload) => {
    if (dispatch) dispatch(payload);
  };
  const onUploadProgress = ({ loaded, total }) => {
    const progress = Math.round((loaded * 100) / total);
    action({
      type: 'LOADING_PROGRESS',
      progress: progress,
      value: true,
    });
  };
  try {
    const pathname = path.replace('{token}', state.token);
    const url = `${do_url}${pathname}`;
    const headers = {
      'content-type': 'multipart/form-data',
    };
    if (state.token) headers.Authorization = `Bearer ${state.token}`;
    const options = {
      headers,
      onUploadProgress,
    };
    action({ type: 'LOADING_PROGRESS', progress: 1, value: true });
    let promise;
    if (body) promise = axios[method.toLowerCase()](url, body, options);
    else promise = axios[method.toLowerCase()](url, options);
    const response = await promise;
    if (successText) action({ type: 'ALERT', content: successText });
    return { data: response.data };
  } catch (err) {
    captureException(err);
    console.error(err);
    action({ type: 'ALERT', content: errorText || 'Något gick fel' });
    return { err };
  } finally {
    action({ type: 'LOADING_PROGRESS', progress: null, value: false });
  }
};
