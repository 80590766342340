import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import Button from './Button';
import DateInput from './DateInput';
import { useInterval } from '../utils/hooks';
import { ONE_HOUR } from '../utils/time';

const StyledCommunicationLogForm = styled.form``;

const initialFormData = {
  contactPerson: '',
  comment: '',
  date: new Date().toLocaleDateString('sv'),
};

const CommunicationLogForm = ({ handleSubmit, data, contactPersons }) => {
  const [formData, setFormData] = useState(initialFormData);
  const [shouldSetTodayDate, setShouldSetTodayDate] = useState(true);

  const onChange = (e) => {
    if (e.target.name === 'date') setShouldSetTodayDate(false);
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const onSubmit = (e) => {
    e.preventDefault();
    handleSubmit(formData, () => setFormData(initialFormData));
  };

  const setTodayDate = () => {
    setFormData({
      ...formData,
      date: new Date().toLocaleDateString('sv'),
    });
  };

  useInterval({
    action: setTodayDate,
    active: shouldSetTodayDate,
    timeMs: ONE_HOUR,
  });

  useEffect(() => {
    if (data) setFormData(data);
  }, [data]);

  return (
    <StyledCommunicationLogForm onSubmit={onSubmit}>
      {/* eslint-disable-next-line jsx-a11y/no-onchange */}
      <select
        name="contactPerson"
        value={formData.contactPerson}
        onChange={onChange}
      >
        <option disabled value="">
          Välj kontaktperson
        </option>
        {contactPersons.map((person) => (
          <option key={person._id} value={person.name}>
            {person.name}
          </option>
        ))}
      </select>
      <p>Datum</p>
      <DateInput
        required
        value={formData.date}
        name="date"
        onChange={onChange}
      />
      <textarea
        required
        name="comment"
        value={formData.comment}
        onChange={onChange}
        className="inner"
        placeholder="Skriv din text här"
      />
      <Button type="submit" small>
        Spara
      </Button>
    </StyledCommunicationLogForm>
  );
};

export default CommunicationLogForm;
