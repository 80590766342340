import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import Button from './Button';
import DateInput from './DateInput';

const StyledCustomerContactForm = styled.div`
  .dual {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1rem;
  }
`;

const initialFormData = {
  lastContact: '',
  serviceDealStart: '',
  serviceDealEnd: '',
};

const CustomerContactForm = ({ handleSubmit, data }) => {
  const [formData, setFormData] = useState(initialFormData);

  const onChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const onSubmit = (e) => {
    e.preventDefault();
    const payload = {
      contactInformation: {
        lastContact: formData.lastContact,
        serviceDeal: {
          startDate: formData.serviceDealStart,
          endDate: formData.serviceDealEnd,
        },
      },
    };
    handleSubmit(payload);
  };

  useEffect(() => {
    if (data) setFormData(data);
  }, [data]);

  return (
    <StyledCustomerContactForm onSubmit={onSubmit}>
      <form className="change-contact-information-form">
        <div className="form-pair">
          <p>Sista kontakt</p>
          <DateInput
            name="lastContact"
            value={formData.lastContact}
            onChange={onChange}
          />
        </div>
        <div className="dual">
          <div className="form-pair">
            <p>Serviceavtal start</p>
            <DateInput
              name="serviceDealStart"
              value={formData.serviceDealStart}
              onChange={onChange}
            />
          </div>
          <div className="form-pair">
            <p>Serviceavtal slut</p>
            <DateInput
              name="serviceDealEnd"
              value={formData.serviceDealEnd}
              onChange={onChange}
            />
          </div>
        </div>
        <Button type="submit">Spara</Button>
      </form>
    </StyledCustomerContactForm>
  );
};

export default CustomerContactForm;
