import { request } from './request';
import * as Sentry from '@sentry/gatsby';

const emptyPerson = {
  _id: '',
  name: '',
  email: '',
  phone: '',
  title: '',
  mobile: '',
};

export const getPersons = async ({ dispatch, state }) => {
  const { err, data } = await request({
    method: 'GET',
    path: 'persons',
    state,
    dispatch,
  });

  if (err) {
    return { err };
  }

  return { persons: data };
};

export const getPersonById = (persons, id) => {
  if (!persons.length) return emptyPerson;
  const person = persons.find((person) => person._id === id);
  if (!person)
    Sentry.captureException(new Error(`Person with id ${id} not found`));
  return person || emptyPerson;
};
